import { useUser } from "@/hooks/useUser";
import { getLayout } from "@/layouts/FullBleedLayout";
import HomePage from "@/pages/home";

function IndexPage() {
  useUser({ redirectTo: "/leagues", redirectIfFound: true });

  return <HomePage />;
}
export default IndexPage;
IndexPage.getLayout = getLayout;
